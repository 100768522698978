<!--
 * @Author: ChenYaJin
 * @Date: 2023-07-19 11:29:28
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-12 17:21:30
 * @Description: 审核状态栏
-->
<template>
  <div class="status-row">
    <div class="status-item">
      <label class="item-title">审核内容:</label>
      <el-tag type="success" size="large">{{ props.auditStatusInfo.content || '-' }}</el-tag>
    </div>
    <div v-if="auditStatusInfo.content !== '企业基本情况'" class="status-item">
      <label class="item-title">审核人:</label>
      <el-tag v-if="props.auditStatusInfo.reviewBy" type="success" size="large">
        {{ props.auditStatusInfo.reviewBy || '-' }}
      </el-tag>
      <span v-else>--</span>
    </div>
    <div v-if="auditStatusInfo.content !== '企业基本情况'" class="status-item">
      <label class="item-title">审核结果:</label>
      <el-tag size="large" :type="getStatusType(auditStatusInfo.auditStatus)">
        {{ getStatusName(auditStatusInfo.auditStatus) }}
      </el-tag>
    </div>
    <div class="status-item flex-y-center">
      <label class="item-title">说明:</label>
      <el-tooltip
        class="box-item"
        effect="Light"
        :content="auditStatusInfo.reject"
        placement="bottom-end"
      >
        <span class="item-content text-overflow flex-1" :label="props.auditStatusInfo.reject">
          {{ props.auditStatusInfo.reject || '无' }}
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IAuditStatusInfo, IOption } from '~/models/common'
  import { useOptionStore } from '~/stores/modules/option'

  defineOptions({
    name: 'AuditStateComponent',
  })

  const props = defineProps({
    auditStatusInfo: {
      type: Object as PropType<IAuditStatusInfo>,
      default: () => {
        return {
          content: '',
          reviewBy: '',
          auditStatus: '',
          reject: '',
          score: 0,
        }
      },
    },
  })
  const optionStore = useOptionStore()
  const statusList = ref<IOption[]>([])

  const getStatusList = async () => {
    statusList.value = await optionStore.getStatusListInfo()
  }
  const getStatusName = (code = '') => {
    return optionStore.getStatusName(code)
  }
  const getStatusType = (code = '') => {
    return optionStore.getStatusTagType(code)
  }

  getStatusList()
</script>

<style scoped lang="scss">
  .status-row {
    @apply inline-flex items-center w-full p-2.4rem text-left;
    background-color: #f7f9fb;
    flex: 1;
    .status-item {
      @apply min-w-20%;
    }
    .item-title {
      @apply mr-0.5rem;
    }
    .item-content {
      @apply inline-block max-h-80% vertical-bottom;
    }
  }
</style>
